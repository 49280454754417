/*global Component,uuidv4*/
class valueFilterComponent extends Component {

    static name() {
        return "valueFilterComponent";
    }

    static componentName() {
        return "valueFilterComponent";
    }

    getProps() {
        return ['filterField'];
    }

    data() {
        return {
            display:true,
            isMobile: $(window).width() <= 560,
        };
    }

    mounted() {
        return async function () {
            let self = this;
            this.display = !this.isMobile;
            $(window).resize(function () {
                self.isMobile = $(window).width() <= 560;
                self.display = !self.isMobile;
            });
        };
    }

    getComputed() {
        return {
            collections: function () {
                let collection = new Map();
                let items = this.$store.getters.getItems;
                for (let item of items) {
                    let value = item[this.filterField.ItemField];
                    if (value) {
                        if (!collection.has(value)) {
                            let filter = [];
                            for (let valueSplit of value.toString().split(',')) {
                                let objectFilter = {};
                                objectFilter.name = 'valueFilter' + uuidv4();
                                objectFilter.condition = `x.${this.filterField.ItemField}.indexOf('${valueSplit}')!=1`;
                                objectFilter.result = true;
                                objectFilter.active = false;
                                filter.push(objectFilter);
                            }
                            collection.set(value, [value, filter]);
                        }
                    }
                }
                return Array.from(collection.values());
            }
        };
    }

    getMethods() {
        return {
            launchFilter:this.launchFilter
        };
    }

    launchFilter(filters) {
        filters.active = true & filters.active;
        this.$store.dispatch('addFilter',[filters.name,filters]);
    }

    getTemplate() {
        return `<div class="filter" v-if="collections.length>0">
                    <div>
                        <p class="filter-title" @click="display = !display">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                            <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                        </p>
                    </div>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul class="unstyled">
                          <template v-for="value of collections">
                            <li :key="'li-value-'+value.name" v-if="value.itemCount>0">
                                <div class="custom-control custom-checkbox">
                                    <input :key="'input'+value.name" class="custom-control-input" type="checkbox" v-model="value.active" :id="value.label" @click="launchFilter(value)" />
                                    <label :for="value.label" class="custom-control-label">{{value.label}} ({{value.itemCount}})</label>
                                </div>
                            </li>
                          </template>
                        </ul>
                    </section>
               </div>`;
    }
}

valueFilterComponent.registerComponent();